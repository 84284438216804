<template>
    <div>
        <!--  REAL CHECKBOX INPUT (visually hidden)  -->
        <input v-model="checkboxValue"
               :type="type"
               class="visually-hidden"
               :value="value"
               :checked="isChecked"
               :aria-label="ariaLabel"
               :name="name"
               :disabled="disabled"
        >

        <!--  CUSTOM CHECKBOX ELEMENT  -->
        <div :class="inputClass ?? [
                 'sim-checkbox',
                 {
                     'sim-checkbox--checked': isChecked,
                     'sim-checkbox--disabled': disabled,
                 }
             ]"
             v-bind="parentScope"
             @click="toggleCheckboxValue"
        >
            <!--  @slot Custom checkbox element content
                  @binding { boolean } checked Whether the checkbox is checked or not.
              -->
            <slot name="checkbox" :checked="!!checkboxValue" />
        </div>
    </div>
</template>

<script lang="ts" setup>

type CheckboxValue = string | boolean | number | object | null
const checkboxValue = defineModel<CheckboxValue | CheckboxValue[]>()

const {
    value,
    type = 'checkbox',
    ariaLabel,
    name,
    disabled,
    inputClass,
    allowRadioUncheck,
} = defineProps<{
    value?: string | boolean | number
    type?: 'checkbox' | 'radio'
    ariaLabel?: string
    name?: string
    disabled?: boolean
    inputClass?: string | (string | Record<string, boolean>)[] | Record<string, boolean>
    allowRadioUncheck?: boolean
}>()

const parentScope = getParentScope()

const isChecked = computed(() => {
    if (typeof checkboxValue.value === 'boolean') {
        return checkboxValue.value
    }

    if (Array.isArray(checkboxValue.value)) {
        return checkboxValue.value.includes(value!)
    }

    return checkboxValue.value === value
})

function toggleCheckboxValue() {
    if (disabled) return

    // allow unchecking radio buttons
    if (isChecked.value && type === 'radio' && allowRadioUncheck) {
        checkboxValue.value = null
        return
    }

    if (typeof checkboxValue.value === 'boolean') {
        checkboxValue.value = !checkboxValue.value
        return
    }

    if (Array.isArray(checkboxValue.value)) {
        if (checkboxValue.value.includes(value!)) {
            checkboxValue.value = checkboxValue.value.filter(v => v !== value)
        } else {
            checkboxValue.value = [...checkboxValue.value, value!]
        }
        return
    }

    checkboxValue.value = value
}

</script>

<style lang="scss" scoped>

</style>
